<template>
    <div>
    <el-dialog v-if="visible"
            :title="title"
            :close-on-click-modal="false"
            append-to-body
            :visible.sync="visible" class="dialogForm" width="96%">
        <el-form size="small" :model="inputForm"  v-loading="loading" :rules="dataRule" ref="inputForm" @keyup.enter.native="step()"
                 label-width="140px" @submit.native.prevent>
            <el-form-item label="报表名称：" prop="name">
                <el-input v-model.trim="inputForm.name" placeholder="请输入报表名称" maxlength="50"></el-input>
            </el-form-item>
            <el-form-item label="报表类型：" prop="reportType">
                <el-select v-model="inputForm.reportType" placeholder="请选择报表类型"  style="width: 100%;">
                    <el-option
                            v-for="item in $dictUtils.getDictList('report_type')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-card>
                    <el-button type="primary" icon="eye" @click="preViewReport">
                        预览
                    </el-button>
                <el-button type="danger" @click="clearPaper">
                    清空
                    <el-icon type="close"/>
                </el-button>
                <el-row :gutter="8" style="margin-top: 10px">
                    <el-col :span="4">
                        <el-card style="height: 100vh">
                            <el-row>
                                <el-col :span="24" class="rect-printElement-types hiprintEpContainer">
                                    <el-row class="drag_item_title">拖拽组件列表</el-row>
                                    <el-row style="height: 100px;">
                                        <el-col :span="12" class="drag_item_box">
                                            <div>
                                                <a class="ep-draggable-item" tid="defaultModule.text" style>
                                                    <span class="el-icon-document-remove" ariel-hidden="true"></span>
                                                    <p class="glyphicon-class">文本</p>
                                                </a>
                                            </div>
                                        </el-col>
                                        <el-col :span="12" class="drag_item_box" tid="defaultModule.text">
                                            <div>
                                                <a class="ep-draggable-item" tid="defaultModule.longText">
                                                    <span class="el-icon-tickets" ariel-hidden="true"></span>
                                                    <p class="glyphicon-class">长文</p>
                                                </a>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row style="height: 100px;">
                                        <el-col :span="12" class="drag_item_box" tid="defaultModule.text">
                                            <div>
                                                <a class="ep-draggable-item" tid="defaultModule.tableCustom" style>
                                                    <span class="el-icon-s-grid" ariel-hidden="true"></span>
                                                    <p class="glyphicon-class">表格</p>
                                                </a>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-col>
                    <el-col :span="15">
<!--                       自定义打印区域 -->
                        <el-card class="card-design">
                            <div id="hiprint-printTemplate" class="hiprint-printTemplate"></div>
                        </el-card>
                    </el-col>
<!--                    参数设置容器-->
                    <el-col :span="5" class="params_setting_container">
                        <el-card>
                            <el-row class="hinnn-layout-sider">
                                <div id="PrintElementOptionSetting"></div>
                            </el-row>
                        </el-card>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="step()" v-noMoreClick>保存</el-button>
    </span>
    </el-dialog>
        <!-- 预览 -->
        <print-preview  ref="preView"/>
    </div>
</template>

<script>
    import {hiprint, defaultElementTypeProvider} from '../../../../../utils/report'
    // import 'bootstrap/dist/css/bootstrap.min.css';
    let hiprintTemplate;
    import printPreview from './preview'
    export default {
        components: {printPreview},
        data () {
            return {
                visible: false,
                loading: false,
                title: '',
                method: '',
                inputForm: {
                    id: '',
                    name: '',
                    enable:0,
                    reportType:"",
                    json:{panels:''}
                },
                dataRule:{
                    name: [{required: true, message: '报表名称不能为空', trigger: 'blur'}],
                    reportType:[{required: true, message: '报表类型不能为空', trigger: 'blur'}],
                },
                ftpList:[],
                curPaper: {
                    type: 'A4',
                    width: 210,
                    height: 296.6
                },
            }
        },
        methods: {
            init (method, row) {
                this.method = method
                this.inputForm.id = ""
                if (method === 'add') {
                    this.title = `新建报表模板`
                } else if (method === 'edit') {
                    this.title = '编辑报表模板'
                    // this.inputForm = row
                }
                this.visible = true
                this.$nextTick(() => {
                    if (method === 'edit') { // 修改
                        this.inputForm = this.recover(this.inputForm, row)
                        this.inputForm.json = JSON.parse(row.json)
                    }
                    hiprint.init({
                        providers: [new defaultElementTypeProvider()]
                    });
                    // 还原配置
                    // hiprint.setConfig()
                    // // 替换配置
                    // hiprint.setConfig({
                    //     movingDistance: 2.5,
                    //     text:{
                    //         supportOptions: [
                    //             {
                    //                 name: 'styler',
                    //                 hidden: true
                    //             },
                    //             {
                    //                 name: 'formatter',
                    //                 hidden: true
                    //             },
                    //         ]
                    //     }
                    // })
                    // eslint-disable-next-line no-undef
                    hiprint.PrintElementTypeManager.buildByHtml($('.ep-draggable-item'));
                    hiprintTemplate = new hiprint.PrintTemplate({
                        template: this.inputForm.json,
                        settingContainer: '#PrintElementOptionSetting',
                        paginationContainer: '.hiprint-printPagination'
                    });
                    hiprintTemplate.design('#hiprint-printTemplate');
                    if(method === 'add'){
                        this.clearPaper()
                        this.inputForm.name=""
                    }
                })
            },
            //保存
            step(){
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        let api = this.api.report.templateUpdateById
                        let type = 'put'
                        if(this.method==`add`){
                            api = this.api.report.templateSave
                            type = 'post'
                        }
                        this.loading = true
                        this.inputForm.json = JSON.stringify(hiprintTemplate.getJson())
                        this.$axios(api, this.inputForm, type).then(data => {
                            this.loading = false
                            if (data && data.status) {
                                this.$message.success(data.msg)
                                this.visible = false
                                this.$emit('refreshDataList')
                            }
                        })
                    }else{
                        return false;
                    }
                })
            },

            preViewReport() {
                this.$refs.preView.show(hiprintTemplate, this.inputForm.json)
            },
            clearPaper() {
                try {
                    hiprintTemplate.clear();
                } catch (error) {
                    this.$message.error(`操作失败: ${error}`);
                }
            },
        }
    }

</script>
<!--<style scoped src="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap.min.css"/>-->
<style scoped>
    /*@import 'https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap.min.css';*/


    .drag_item_box {
        height: 100%;
        padding: 6px;
    }

    .drag_item_box > div {
        height: 100%;
        width: 100%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .drag_item_box > div > a {
        text-align: center;
        text-decoration-line: none;
    }

    .drag_item_box > div > a > span {
        font-size: 28px;
    }

    .drag_item_box > div > a > p {
        margin: 0;
    }
    .drag_item_title {
        font-size: 16px;
        padding: 12px 6px 0 6px;
        font-weight: bold;
    }
    .card-design {
       overflow: hidden;
       overflow-x: auto;
       overflow-y: auto;
   }
</style>
